.card-bg-primary {
    background-color: navy;
}

.cross-mark {
    font-size: 36px;
    color: red;
}

.check-mark {
    font-size: 36px;
    color: green;
}