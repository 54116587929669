.card-bg-primary {
  background-color: navy;
}

.cross-mark {
  color: red;
  font-size: 36px;
}

.check-mark {
  color: green;
  font-size: 36px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

#announcement {
  font-family: Arial, sans-serif;
  font-size: calc(1em + .5vw);
}

#program {
  background-color: #ffffff4d;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
  box-shadow: 0 4px 8px #0000001a;
}

.neg-bottom-margin {
  margin-bottom: -40%;
}
/*# sourceMappingURL=index.12cf7ffe.css.map */
